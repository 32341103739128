import BaiaAuditoriaRepository from "../../../repositories/v2/BaiaAuditoriaRepository";

export class BaiaAuditoriaService {
  vm;
  status;

  baiaAuditoriaRepository = BaiaAuditoriaRepository.build();

  constructor(baiaAuditoriaRepository) {
    this.baiaAuditoriaRepository = baiaAuditoriaRepository;
  }

  static build() {
    const baiaAuditoriaRepository = BaiaAuditoriaRepository.build();
    return new BaiaAuditoriaService(baiaAuditoriaRepository);
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async setSearch(volume) {
    this.loading = true;
    try {
      const response = await this.baiaAuditoriaRepository.getPedidoPerVolume(
        volume
      );
      this.vm.pedidos = response.data.data;
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async confirmError(volumeId) {
    try {
      const response =
        await this.baiaAuditoriaRepository.postConfirmErrorPedido(volumeId);
      console.log(response);
      if (response.data.status === 200) {
        console.log(response);
        return;
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getListagem(filtros) {
    this.vm.loading = true;
    this.vm.pedidos = [];
    try {
      const response = await this.baiaAuditoriaRepository.getListagem({
        page: this.vm.page,
        filtros: filtros,
      });
      if (response.status !== 200) {
        this.vm.pedidos = response.data.data;
        throw response;
      }
      const { pagination, list } = response.data.data;
      if (!list) {
        throw response;
      }
      this.vm.pedidos = list;
      this.vm.pagination = pagination;
      this.vm.loading = false;
    } catch (e) {
      console.error({ e });
      this.vm.loading = false;
    }
  }

  async filter(filtros) {
    this.vm.loading = true;
    this.vm.pedidos = [];
    try {
      const response = await this.baiaAuditoriaRepository.getListagem({
        page: this.vm.page,
        filtros: filtros,
      });
      this.vm.loading = false;
      if (response.status !== 200) {
        this.vm.pedidos = response.data.data;
        throw response;
      }

      const { data } = response.data;
      const { pagination, list } = data;
      this.vm.pedidos = list;
      this.vm.pagination = pagination;
    } catch (e) {
      console.error({ e });
      this.vm.loading = false;
    }
  }
}
