import { render, staticRenderFns } from "./ConfirmBaiaModal.vue?vue&type=template&id=47c09aca&scoped=true&"
import script from "./ConfirmBaiaModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmBaiaModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmBaiaModal.vue?vue&type=style&index=0&id=47c09aca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c09aca",
  null
  
)

export default component.exports