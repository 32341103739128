<template>
  <div class="listing-card my-2">
    <b-row no-gutters style="align-items: center">
      <b-col sm="3" md="3" lg="3" xl="3" class="listing-section">
        <li>
          <span class="mb-3 d-xl-none d-lg-none">
            <b>Pedido:</b>
          </span>
          {{ pedido.pedido }}
          <small class="d-block text-muted font-italic mt-1">
            <!-- {{ formatarDescricaoPedido(pedido.pedidoStatusDescricao) }} -->
          </small>
        </li>
      </b-col>
      <b-col sm="2" md="2" lg="2" xl="2" class="listing-section">
        <li>
          <span class="mb-3 d-xl-none d-lg-none">
            <b> Volume: </b>
          </span>
          {{ pedido.volume }}
        </li>
      </b-col>
      <b-col sm="2" md="2" lg="2" xl="2" class="listing-section">
        <li>
          <span class="mb-3 d-xl-none d-lg-none">
            <b> Baia: </b>
          </span>
          {{ pedido.baia }}
        </li>
      </b-col>
      <b-col sm="2" md="2" lg="2" xl="2" class="listing-section">
        <li>
          <span class="mb-3 d-xl-none d-lg-none">
            <b> Data: </b>
          </span>
          {{ pedido.dataProducao | dateDMY }}
        </li>
      </b-col>
      <b-col sm="3" md="3" lg="3" xl="3" class="listing-section">
        <li>
          <span class="mb-3 d-xl-none d-lg-none">
            <b> Transportadora: </b>
          </span>
          {{ pedido.transportadora }}
        </li>
      </b-col>
      <!-- <b-col sm="2" md="2" lg="2" xl="2" class="listing-section">
        <li>
          <span class="mb-3 d-xl-none d-lg-none">
            <b> Status: </b>
          </span>

          {{ pedido.transportadoraNome }}
        </li>
      </b-col> -->
    </b-row>
    <confirm-baia-modal :pedido="pedido" />
  </div>
</template>
<script>
import ConfirmBaiaModal from "./ConfirmBaiaModal.vue";
export default {
  components: { ConfirmBaiaModal },
  name: "BaiaAuditoriaList",
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatarDescricaoPedido(descricao) {
      return descricao
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    },
    openModal(pedido) {
      this.$bvModal.show("confirm-baia", pedido);
    },
  },
};
</script>

<style scoped>
.listing-section {
  list-style-type: none;
  border-right: 0.063rem solid rgba(83, 83, 83, 0.14);
  padding: 1rem;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}
.listing-section-item {
  list-style-type: none;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
}
.listing-section-last {
  list-style-type: none;
  padding: 1.25rem;
  text-align: center;
}
.listing-card {
  list-style-type: none;
  width: 100%;
  height: 100%;
  background-color: #eef0f8;
  color: #2e2e2e;
  border-radius: 1rem;
}
.listing-card:hover {
  background-color: rgba(215, 219, 223, 0.84);
  border-radius: 1rem;
  transition: 300ms all ease-in-out;
}
.custom-orange-button {
  color: #cc5d02;
  border-color: #cc5d02;
}
.custom-orange-button:hover,
.custom-orange-button:focus {
  color: #fff;
  background-color: #e6781e;
  border-color: #e6781e;
}
.custom-checkbox {
  border-color: orange !important;
}
.custom-checkbox:checked {
  background-color: orange !important;
}
.custom-checkbox:checked + .form-check-label::before {
  border-color: orange !important;
  background-color: orange !important;
}
.ativo {
  color: green;
}
.inativo {
  color: red;
}
</style>
