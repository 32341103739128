<template>
  <b-modal data-bv
    id="confirm-baia"
    body-class="rounded"
    hide-footer
    hide-header
    centered
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">Confirmação de Auditoria</h4>
      </div>
      <b-row class="my-4">
        <div class="my-10 col-8 text-center mx-auto">
          <input
            class="mr-3"
            id="error-checkbox"
            type="checkbox"
            v-model="errorConfirmed"
          />
          <label for="error-checkbox"
            >Deseja confirmar o erro neste pedido?</label
          >
        </div>
      </b-row>
      <b-row class="my-3">
        <b-col cols="12" class="text-center">
          <div
            @click="confirmError"
            :class="!errorConfirmed ? 'disabled' : ''"
            class="btn btn-lg custom-orange-button"
          >
            Confirmar
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { BaiaAuditoriaService } from "../service/BaiaAuditoriaService";

export default {
  name: "ConfirmBaiaModal",
  data() {
    return {
      errorConfirmed: false,
      baiaAuditoriaService: BaiaAuditoriaService.build(),
    };
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  methods: {
    confirmError() {
      this.baiaAuditoriaService.confirmError(this.pedido);
    },
  },
  created() {
    this.baiaAuditoriaService = BaiaAuditoriaService.build().setVm(this);
  },
  mounted() {
    this.baiaAuditoriaService.setVm(this);
  },
};
</script>

<style scoped>
.custom-orange-button {
  color: white;
  background-color: #cc5d02;
  /* border-color: #cc5d02; */
}
</style>
