<template>
  <div class="content">
    <div class="box-content">
      <BaiaAuditoriaHeader @searchFilter="search" />
      <div class="list-component">
        <div>
          <b-row no-gutters>
            <b-col
              cols="3"
              class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>Pedido</b></li>
            </b-col>
            <b-col
              cols="2"
              class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>Volume</b></li>
            </b-col>
            <b-col
              cols="2"
              class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>Baia</b></li>
            </b-col>
            <b-col
              cols="2"
              class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>Data</b></li>
            </b-col>
            <b-col
              cols="3"
              class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
            >
              <li><b>Transportadora</b></li>
            </b-col>

            <!-- <b-col
              cols="2"
              class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block text-truncate"
            >
              <li><b>Status</b></li>
            </b-col> -->
          </b-row>
        </div>
      </div>
      <div
        v-if="loading"
        class="pad mt-5"
        style="text-align: center !important"
      >
        <b-spinner style="width: 10rem; height: 10rem" />
      </div>

      <div>
        <div class="my-3">
          <baia-auditoria-list
            :pedido="pedido"
            v-for="(pedido, index) in pedidos"
            :key="index"
          />
        </div>
      </div>

      <div class="pagination">
        <sliding-pagination
          v-if="pagination.lastPage && pedidos.length"
          :current="pagination.currentPage"
          :total="pagination.lastPage"
          @page-change="paginate"
        />
      </div>
      <div v-if="pedidos.length == 0 && !loading" class="text-center">
        <b-col>
          <span>Não há pedidos disponíveis.</span>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import BaiaAuditoriaHeader from "../components/BaiaAuditoriaHeader.vue";
import SlidingPagination from "vue-sliding-pagination";
import BaiaAuditoriaList from "../components/BaiaAuditoriaList.vue";
import { BaiaAuditoriaService } from "../service/BaiaAuditoriaService";
export default {
  name: "BaiaAuditoriaView",
  components: { BaiaAuditoriaHeader, SlidingPagination, BaiaAuditoriaList },
  data: () => ({
    loading: false,
    pedidos: [],
    baiaAuditoriaService: BaiaAuditoriaService.build(),
    pagination: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 0,
      previousPage: 0,
      nextPage: 0,
    },
    page: 1,
  }),
  created() {
    this.baiaAuditoriaService = BaiaAuditoriaService.build().setVm(this);
  },
  mounted() {
    this.baiaAuditoriaService.setVm(this);
    this.getListagem();
  },

  methods: {
    async search(filter) {
      this.baiaAuditoriaService.getListagem(filter);
    },
    async getListagem() {
      await this.baiaAuditoriaService.getListagem();
    },
    async paginate(page) {
      this.page = page;
      await this.baiaAuditoriaService.getListagem();
    },
  },
};
</script>

<style scoped>
.list-titles {
  list-style-type: none;
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
  padding: 0.8rem;
  text-align: center;
}
li {
  font-weight: normal;
  font-size: 1rem;
}
.list-component {
  background-color: #120947;
  color: #ffffff;
  border-top: 5px solid#00bfcf;
}
</style>
