<template>
  <div class="listing-header">
    <b-row class="mt-4 align-items-center justify-content-end">
      <!-- <b-col cols="1" sm="2" md="1" lg="1" xl="1">
        <router-link
          :to="{
            name: 'AuditoriaProtocoloView',
          }"
        >
          <b-button
            class="custom-orange-button mb-3"
            @click="goToVolume()"
            style="text-transform: none !important; font-weight: bolder"
          >
            <b-icon icon="arrow-left-circle-fill" font-scale="1"></b-icon>
          </b-button>
        </router-link>
      </b-col> -->
      <!-- <b-col cols="4" sm="4" md="4" lg="4" xl="4" class="text-right">
        <input
          v-model="numeroPedido"
          class="filter-input"
          placeholder="Número do Volume"
          id="order"
          name="order"
          type="number"
          @keydown.prevent.enter="filter"
        />
      </b-col> -->
      <filter-list @filter="filter" />
    </b-row>
  </div>
</template>
<script>
import FilterList from "@/shared/components/FilterList.vue";

export default {
  name: "BaiaAuditoriaHeader",
  emits: ["searchFilter"],
  data: () => ({
    numeroPedido: "",
  }),
  components: {
    FilterList,
  },
  methods: {
    filter(filtros) {
      console.log(filtros);
      this.$emit("searchFilter", filtros);
    },
  },
};
</script>
<style scoped>
.listing-title {
  font-family: inherit;
  font-weight: bold;
  color: #2e2e2e;
}

.default-button-orange-color {
  background-color: #cc5d02;
  color: white;
  border: none;
  outline: none;
}
.listing-header {
  margin-bottom: 2.1rem;
}

.custom-orange-button {
  background-color: #cc5d02;
  color: white;
  border: none;
  outline: none;
}
</style>
